/* eslint-disable class-methods-use-this */
import http from '@/http';
import Service from './Service';

class ProjectService extends Service {
  constructor() {
    super('projects');
  }

  updateInverter(payload) {
    return http.put(`${this.baseUrl}/update-kit/${payload.project_id}`, payload);
  }

  getByClientId(id) {
    return http.get(`${this.baseUrl}/getByClient/${id}`);
  }

  deleteDiscount(data) {
    return http.post(`${this.baseUrl}/delete-discount`, data);
  }

  getFieldsTableProjects() {
    return [
      {
        key: 'id',
        label: 'ID',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'client',
        label: 'Cliente',
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
      },
      {
        key: 'agent',
        label: 'Agente',
      },
      {
        key: 'city',
        label: 'Cidade',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'province',
        label: 'UF',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'value_project',
        label: 'Valor',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'created_at',
        label: 'Data',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'actions',
        label: '',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
    ];
  }

  getFieldsTableComponents() {
    return [
      {
        key: 'description',
        label: 'Descrição',
        tdClass: 'text-nowrap text-uppercase',
        thClass: 'text-nowrap',
      },
      {
        key: 'quantity',
        label: 'Quantidade',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
    ];
  }

  getFieldsTableAccompaniment() {
    return [
      {
        key: 'department',
        label: 'Departamento',
        tdClass: 'text-nowrap text-uppercase',
        thClass: 'text-nowrap',
      },
      {
        key: 'status',
        label: 'Status',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'operator',
        label: 'Operador',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
    ];
  }

  getFieldsTableDiscounts() {
    return [
      {
        key: 'user',
        label: 'Usuário',
        tdClass: 'text-nowrap text-uppercase',
        thClass: 'text-nowrap',
      },
      {
        key: 'value_project',
        label: 'Valor projeto',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'percentage_discount',
        label: 'Desconto (%)',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'value_discount',
        label: 'Desconto de R$',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'price_final',
        label: 'Preço final',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'actions',
        label: 'Ações',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
    ];
  }
}

const projectService = new ProjectService();
export default projectService;
